import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-hot-toast";

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASEURL,

	prepareHeaders: (headers, { endpoint }) => {
		const Token = localStorage.getItem("Token") || "";
		if (Token && endpoint !== "giolocation") {
			headers.set("authorization", `${Token}`);
		}
		return headers;
	},
});

const baseQueryWithErrorHandler = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result.error) {
		switch (result.error.status) {
			case 401:
				localStorage.removeItem("Token");
				localStorage.removeItem("Email");
				window.location.replace("/");
				break;

			case 500:
				window.location.replace("/error-500");
				break;

			case 404:
				window.location.replace("/error-404");
				break;

			case 405:
				window.location.replace("/error-403");
				break;

			default:
				toast.error(
					result.error.data?.message ||
						"Something went wrong! Try again later..."
				);
				break;
		}
	}
	return result;
};

export const apiSlice = createApi({
	reducerPath: "apiSlice",
	baseQuery: baseQueryWithErrorHandler,

	// All the endpoints are injected here from other files.
	endpoints: () => ({}),
});
