import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./layout/layoutSlice.jsx";
import user_infoReducer from "./user_info.jsx";
import sidebarReducer from "./sidebar.jsx";
import { apiSlice } from "../api/baseQuery.js";

export const store = configureStore({
	reducer: {
		layout: layoutReducer,
		user_info: user_infoReducer,
		sidebar: sidebarReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiSlice.middleware),
});
