import { createSlice } from "@reduxjs/toolkit";
import {
	layoutTypes,
	layoutWidthTypes,
	topBarThemeTypes,
	layoutTheme,
	layoutPositions,
	leftSidebarTypes,
	leftSideBarThemeTypes,
} from "../../constants/layout";

export const layoutSlice = createSlice({
	name: "layout",
	initialState: {
		layoutType: layoutTypes.VERTICAL,
		layoutWidth: layoutWidthTypes.FLUID,
		leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
		leftSideBarType: leftSidebarTypes.DEFAULT,
		layoutMode: layoutTheme.LIGHTMODE,
		topbarTheme: topBarThemeTypes.LIGHT,
		isPreloader: true,
		showRightSidebar: false,
		layoutPosition: layoutPositions.SCROLLABLE_FALSE,
		isMobile: false,
		showSidebar: true,
		leftMenu: false,
	},
	reducers: {
		CHANGE_LAYOUT: (state, action) => {
			state.layoutType = action.payload;
		},
		CHANGE_PRELOADER: (state, action) => {
			state.isPreloader = action.payload;
		},
		CHANGE_LAYOUT_WIDTH: (state, action) => {
			state.layoutWidth = action.payload;
		},
		CHANGE_SIDEBAR_THEME: (state, action) => {
			state.leftSideBarTheme = action.payload;
		},
		CHANGE_SIDEBAR_TYPE: (state, action) => {
			state.leftSideBarType = action.payload.sidebarType;
		},
		CHANGE_TOPBAR_THEME: (state, action) => {
			state.topbarTheme = action.payload;
		},
		CHANGE_LAYOUT_POSITION: (state, action) => {
			state.layoutPosition = action.payload;
		},
		CHANGE_LAYOUT_THEME: (state, action) => {
			state.layoutMode = action.payload;
			state.topbarTheme = action.payload;
			state.leftSideBarTheme = action.payload;
			document.body.setAttribute("data-topbar", action.payload);
			document.body.setAttribute("data-sidebar", action.payload);
			document.body.setAttribute("data-layout-mode", action.payload);
		},
		SHOW_RIGHT_SIDEBAR: (state, action) => {
			state.showRightSidebar = action.payload;
		},
		SHOW_SIDEBAR: (state, action) => {
			state.showSidebar = action.payload;
		},
		TOGGLE_LEFTMENU: (state, action) => {
			state.leftMenu = action.payload;
		},
	},
});

export const {
	TOGGLE_LEFTMENU,
	SHOW_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_THEME,
	CHANGE_LAYOUT_POSITION,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_PRELOADER,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
} = layoutSlice.actions;

export default layoutSlice.reducer;
