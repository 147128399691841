import { createSlice } from "@reduxjs/toolkit";

const user_info = createSlice({
	name: "sidebar",
	initialState: { collapsed: false },
	reducers: {
		expland(state, action) {
			state.collapsed = action.payload;
		},
		collapse(state, action) {
			state.collapsed = action.payload;
		},
	},
});

export const { expland, collapse } = user_info.actions;
export default user_info.reducer;
