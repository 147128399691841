import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

export default function Role_Auth({ allowedRoles }) {
	const navigate = useNavigate();
	const user_role = useSelector((state) => state.user_info.info.role);

	useEffect(() => {
		if (user_role && allowedRoles.indexOf(user_role) <= -1) {
			navigate("not_found", { replace: true });
		}
	}, [allowedRoles, navigate, user_role]);

	return <Outlet />;
}
