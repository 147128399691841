import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { Toaster } from "react-hot-toast";
import Role_Auth from "./utils/Role_auth";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";

const AppealsDetails = loadable(() =>
	import("./pages/P2P/Appeals/Details/Index.jsx")
);
const Ads = loadable(() => import("./pages/P2P/Ads/index.jsx"));
const SmtpSettings = loadable(() => import("./pages/SmtpSettings/index.jsx"));
const AdminUsers = loadable(() =>
	import("./pages/Settings/Genral/AdminUser/index.jsx")
);
const EmailTemplates = loadable(() =>
	import("./pages/Settings/Genral/EmailTemplates/index.jsx")
);
const EmailTemplatesDetails = loadable(() =>
	import(
		"./pages/Settings/Genral/EmailTemplates/EmailTemplateDetails/index.jsx"
	)
);
const Appeals = loadable(() => import("./pages/P2P/Appeals/index.jsx"));
const StakingWithdraw = loadable(() =>
	import("./pages/Stakings/StakingWithdraw/index.jsx")
);
const Layout = loadable(() => import("./pages/Layouts/Layout.jsx"));
const SentCryptoTab = loadable(() =>
	import("./pages/SentCrypto/SentCryptoTab.jsx")
);
const TransferTab = loadable(() =>
	import("./pages/Transfers/TransfersTab.jsx")
);
const AdminDashbord = loadable(() => import("./pages/AdminDashboard/index.js"));
const SupportDashbord = loadable(() =>
	import("./pages/SupportDashboard/index.js")
);
const StakingPlans = loadable(() =>
	import("./pages/Settings/Plans/Staking/index.jsx")
);
const AllStakings = loadable(() =>
	import("./pages/Stakings/AllStakings/index.jsx")
);
const StakingLogs = loadable(() =>
	import("./pages/Stakings/StakingLogs/index.jsx")
);
const KycDetails = loadable(() => import("./pages/Kyc/KycDetails/index.jsx"));

const Blogs = loadable(() => import("./pages/Blogs/index.jsx"));
const AllKyc = loadable(() => import("./pages/Kyc/AllKyc/index.jsx"));
const PendingKyc = loadable(() => import("./pages/Kyc/PendingKyc/index.jsx"));
const RejectedKyc = loadable(() => import("./pages/Kyc/RejectedKyc/index.jsx"));
const CompletedKyc = loadable(() =>
	import("./pages/Kyc/CompletedKyc/index.jsx")
);
const KycLayout = loadable(() => import("./pages/Layouts/KycLayout.jsx"));
const Users = loadable(() => import("./pages/Users/AllUsers/index.jsx"));
const TicketDetails = loadable(() =>
	import("./pages/Tickets/TicketDetails/index.jsx")
);
const All_Tickets = loadable(() =>
	import("./pages/Tickets/AllTickets/index.jsx")
);
const Login = loadable(() => import("./pages/Authentication/Login.js"));
const Error404 = loadable(() => import("./pages/Utility/Error404"));
const Error403 = loadable(() => import("./pages/Utility/Error403"));
const Error500 = loadable(() => import("./pages/Utility/Error500.js"));
const Ticekts_categories = loadable(() =>
	import("./pages/Tickets/Category/index.jsx")
);
const TwoStepVerfication = loadable(() =>
	import("./pages/AuthenticationInner/TwoStepVerfication")
);
const Auth_Layout = loadable(() => import("./pages/Layouts/Auth_Layout.jsx"));
const Setup_TwoFactorAuth = loadable(() =>
	import("./pages/Authentication/Setup_TwoFactorAuth.jsx")
);
const Verify_2fa = loadable(() =>
	import("./pages/Authentication/Verify_2fa.jsx")
);
const UserDetails = loadable(() =>
	import("./pages/Users/UserDetails/index.jsx")
);
const Withdraw = loadable(() =>
	import("./pages/Transactions/Withdraw/index.jsx")
);
const DepositLogs = loadable(() =>
	import("./pages/Transactions/DepositLogs/index.jsx")
);
const ActivityLogs = loadable(() => import("./pages/ActivityLogs/index.jsx"));
const AdminActivityLogs = loadable(() =>
	import("./pages/AdminActivityLogs/index.jsx")
);
const UserWallet = loadable(() => import("./pages/UserWallets/index.jsx"));
const CryptoBalances = loadable(() =>
	import("./pages/CryptoBalances/index.jsx")
);

import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { bscTestnet, mainnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

export const M20 = {
	id: 323,
	name: "Mcoin Network",
	network: "mcoin",
	nativeCurrency: {
		decimals: 18,
		name: "Mcoin",
		symbol: "MCOIN",
	},
	rpcUrls: {
		public: { http: ["https://rpc1.m20chain.com"] },
		default: { http: ["https://rpc1.m20chain.com"] },
	},
	blockExplorers: {
		default: { name: "m20Chain", url: "https://explorer.m20chain.com" },
	},
};

const App = () => {
	const { publicClient, webSocketPublicClient } = configureChains(
		[mainnet, bscTestnet, M20],
		[publicProvider()]
	);

	const config = createConfig({
		publicClient,
		webSocketPublicClient,
	});

	return (
		<>
			<WagmiConfig config={config}>
				<BrowserRouter>
					<Routes>
						{/* auth */}
						<Route element={<Auth_Layout />}>
							<Route path="/" element={<Login />} />
							<Route path="/verify-otp" element={<TwoStepVerfication />} />
							<Route path="/setup-2fa" element={<Setup_TwoFactorAuth />} />
							<Route path="/verify-2fa" element={<Verify_2fa />} />
						</Route>

						<Route element={<Layout />}>
							{/* Dashboard Routes */}
							<Route element={<Role_Auth allowedRoles={["admin"]} />}>
								<Route path="/admin/dashboard" element={<AdminDashbord />} />
							</Route>
							<Route element={<Role_Auth allowedRoles={["support"]} />}>
								<Route
									path="/support/dashboard"
									element={<SupportDashbord />}
								/>
							</Route>

							{/* blogs */}
							<Route>
								<Route path="/blogs" element={<Blogs />} />
							</Route>

							{/* transactions */}
							<Route>
								<Route path="/transactions/withdraws" element={<Withdraw />} />
								<Route
									path="/transactions/deposit-logs"
									element={<DepositLogs />}
								/>
							</Route>

							{/* users */}
							<Route>
								<Route path="/users/all-users" element={<Users />} />
								<Route path="/users/details" element={<UserDetails />} />
							</Route>

							{/* Kyc */}
							<Route>
								<Route element={<KycLayout />}>
									<Route path="/kyc/all" element={<AllKyc />} />
									<Route path="/kyc/pending" element={<PendingKyc />} />
									<Route path="/kyc/rejected" element={<RejectedKyc />} />
									<Route path="/kyc/completed" element={<CompletedKyc />} />
								</Route>
								<Route path="/kyc/details" element={<KycDetails />} />
							</Route>

							{/* Stakings */}
							<Route>
								<Route
									path="/stakings/all-stakings"
									element={<AllStakings />}
								/>
								<Route path="/staking/logs" element={<StakingLogs />} />
								<Route
									path="/staking/staking-withdraw-request"
									element={<StakingWithdraw />}
								/>
							</Route>

							{/* tickets */}
							<Route>
								<Route path="/tickets/all-tickets" element={<All_Tickets />} />

								<Route
									path="/tickets/details/:id"
									element={<TicketDetails />}
								/>
								<Route
									path="/tickets/category"
									element={<Ticekts_categories />}
								/>
							</Route>

							{/* Settings */}
							<Route>
								<Route
									path="/settings/plans/stakings"
									element={<StakingPlans />}
								/>
								<Route
									path="/settings/genral/admin-users"
									element={<AdminUsers />}
								/>
								<Route
									path="/settings/genral/smtp-settings"
									element={<SmtpSettings />}
								/>
								<Route
									path="/settings/genral/templates"
									element={<EmailTemplates />}
								/>
								<Route
									path="/settings/genral/templates/:name"
									element={<EmailTemplatesDetails />}
								/>
							</Route>

							{/* P2P */}
							<Route>
								<Route path="/p2p/ads" element={<Ads />} />
								<Route path="/p2p/appeals" element={<Appeals />} />
								<Route
									path="/p2p/appeals/details"
									element={<AppealsDetails />}
								/>
							</Route>

							{/* Activity Logs */}
							<Route>
								<Route path="/activity-logs/users" element={<ActivityLogs />} />
								<Route
									path="/activity-logs/admin"
									element={<AdminActivityLogs />}
								/>
							</Route>

							{/* Crypto Balances */}
							<Route>
								<Route path="/crypto-balances" element={<CryptoBalances />} />
							</Route>

							{/* User Wallet */}
							<Route>
								<Route path="/user-wallets/wallets" element={<UserWallet />} />

								<Route
									path="/user-wallets/sent-crypto"
									element={<SentCryptoTab />}
								/>
								<Route
									path="/user-wallets/transfers"
									element={<TransferTab />}
								/>
							</Route>
						</Route>

						{/* misc routes */}
						<Route path="*" element={<Error404 />} />
						<Route path="/error-404" element={<Error404 />} />
						<Route path="/error-500" element={<Error500 />} />
						<Route path="/error-403" element={<Error403 />} />
					</Routes>
				</BrowserRouter>
				<Toaster position="top-center" reverseOrder={false} />
			</WagmiConfig>
		</>
	);
};

export default App;
