import { createSlice } from "@reduxjs/toolkit";

const user_info = createSlice({
	name: "user_info",
	initialState: { info: {} },
	reducers: {
		add_info(state, action) {
			state.info = action.payload;
		},
	},
});

export const { add_info } = user_info.actions;
export default user_info.reducer;
